export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Orders',
        route: 'home',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Analytics',
        route: 'analytics',
        icon: 'FileIcon',
      },
      {
        title: 'StaffUsers',
        route: 'staff-users',
        icon: 'UsersIcon',
        resource: 'staff',
        action: 'write',
      },
    ],
  },
]
